import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import testVertexShader from './shaders/test/vertex.glsl'
import testFragmentShader from './shaders/test/fragment.glsl'
import testVertexShader2 from './shaders/test/vertex2.glsl'
import testFragmentShader2 from './shaders/test/fragment2.glsl'
import VertexShadergx from './shaders/test/vertexgx.glsl'
import FragmentShadergx from './shaders/test/fragmentgx.glsl'
import { DoubleSide, Mesh } from 'three'
import { error, map, nodeName } from 'jquery'

import gsap from "gsap";


/* import * as dat from 'dat.gui' */







import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'




document.getElementById("centertext").style.opacity = "0"

function checkURL(url) {
    return(url.match(/\.(jpeg|jpg|gif|png|JPG)$/) != null);
}

function checkMP3(url) {
    return(url.match(/\.(mp3)$/) != null);
}

//ram
/* 
let ram = navigator.deviceMemory
console.log(ram)
 */

//// VOICE

// PASO 1 ::: DETECTAR EL NAVEGADOR


let compatible = true
if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
    
    compatible = false
    // Do Firefox-related activities
}
window.onload = () =>{
    if( compatible == false){
        alert('El Navegador Firefox(Telefono y escritorio) no son compatible con el Reconocimiento de voz, para una mejor experiencia recomendamos Chrome');
    }
    
    const myTimeout = setTimeout(inicio, 3000);
}


/* if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){

}

if(navigator.userAgent.toLowerCase().indexOf('safari') > -1){

}
 */






let mic = false
let word = "text"
let word2 = "text"
// 2.1 Esta api tiene nombres distintos según el navegador porque aún está en fase experimental, por eso las listamos todas e instanciamos la primera que consiga

let recognition = null
if(compatible){
    recognition = new (window.SpeechRecognition ||  window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition)();
      
                
    // 2.2 Definimos el idioma a escuchar 
    recognition.lang = "es-US";
    
    // 2.3 Configuramos que cuando termine de reconocer algo vuelva a escuchar
    recognition.onend = event => { 
            if(mic){
                recognition.start(); 
            }
            
        }

    // 2.3 Pasamos la función que se llamará cuando haya un resultado del reconocimiento de voz
    recognition.onresult = resultado => {
        if(mic){
            manejarResultado(resultado);  
        }
        }; 
    // 2.4 Empezamos a escuchar
    /* recognition.start(); */
    }




    const manejarResultado = resultado => {
        // 3.1 PINTAMOS LOS RESULTADOS EN EL HTML
        console.log(resultado.results[0][0].transcript)

        /* centerimage.style.content = "url('" + arrayinfo.textimagecss[1] + "')" */
        

        let currentword = resultado.results[0][0].transcript
        let currentwords = currentword.split(" ")
        console.log(currentwords)

        
        if(true){
            for(let i = 0; i < currentwords.length; i++){
                console.log("fase1")
                console.log(arrayinfo.length)
                word = currentwords[i]
                console.log(word)
                console.log(arrayinfo.textpalabra)
                for(let j = 0; j < arrayinfo.textpalabra.length; j++){
                    console.log("fase2")
                    word2 = arrayinfo.textpalabra[j]
                    console.log(word2)
                    if(word == word2){
                        centerimage.style.content = "url('" + arrayinfo.textimagecss[j] + "')"
                        break
                    }
                    else{
                        console.log(word + " no se parece a " + word2)
                    }
                }

            }
        }

    
        // *******BONUS*******
        // Si el resultado es igual a 'abrir wikipedia' abriremos wikipedia
        /* if(resultado.results[0][0].transcript.toLowerCase().trim() == 'abrir wikipedia'){
                            const childFrame = document.createElement('iframe');
                            childFrame.src = "https://es.wikipedia.org/wiki/Wikipedia:Portada";
                            childFrame.style.width = "100vw";
                            childFrame.style.height = "500px";
                            document.body.append(childFrame)
        } */
    }




  





    
















/**
 * Textures
 */
 const textureLoader = new THREE.TextureLoader()
 const particleTexture = textureLoader.load('textures/particles/1.png')
 


 const juan = textureLoader.load(
	// resource URL
	'textures/play.png',

	// onLoad callback
	function ( texture ) {
		// in this example we create the material when the texture is loaded
		console.log(texture.image.width)	},

	// onProgress callback currently not supported
	undefined,

	// onError callback
	function ( err ) {
		console.error( 'An error happened.' );
	}
);









 const PlatTexture = textureLoader.load('textures/play.png')
 PlatTexture.alphaMap = textureLoader.load('textures/playalpha.png')

 const CloseTexture = textureLoader.load('textures/close.png')
 CloseTexture.alphaMap = textureLoader.load('textures/closealpha.png')


 





 

 var centerimage = document.getElementById("centerimage")
 var closeT = document.getElementById("cerrartarjeta")
 var tarjeta = document.getElementById("tarjeta")


 var backimage= document.getElementById("backimage")
 var closeimage = document.getElementById("closeimage")
 var centervideo = document.getElementById("centervideo")
 var esfera = document.getElementById("esfera")
 var paginaprincipal = document.getElementById("paginaprincipal")
 var botonaudio = document.getElementById("botonaudio")
 var textoaudio = document.getElementById("activaraudio")
 var ok = document.getElementById("ok")

 


/*  esfera.onclick = ()=>{
    abierto = true
    clicktrue = true
 } */

 /* paginaprincipal.onclick = ()=>{
     abierto = false
     sound.stop()
     centerimage.style.display = "none"
    centervideo.style.display = "none"
    cantmove = false
    centerimage.  
    controls.enabled = true

    closeimage.style.display = "none"
    clicktrue = false
 }
 */

 
 

let cantmove = true






closeT.onclick = () => {

    tarjeta.style.display = "none"


}

closeimage.onclick = () => {

    centerimage.style.display = "none"
    centervideo.style.display = "none"
    backimage.style.display = "none"
    cantmove = false
    /* centerimage.  */ 
    controls.enabled = true

    closeimage.style.display = "none"
    clicktrue = true
    centervideo.src = ""
    mic = false
    recognition.stop()


    
}




const notcloseimage = () => {

    centerimage.style.display = "none"
    centervideo.style.display = "none"
    backimage.style.display = "none"
    /* cantmove = false */
    /* centerimage.  */ 
    closeimage.style.display = "none"
    clicktrue = true
    centervideo.src = ""
    mic = false
    controls.enabled = true
    
}

let cansound = false
let primeravez = true


ok.onclick = () => {

    tarjeta.style.display = "none"

    if(primeravez){
        primeravez = false
        textoaudio.innerHTML = ""

    }
    if(cansound){
        cansound = false
        botonaudio.src = "textures/soundoff.png"
        sound.pause()
    }
    else{
        sound.play()
        cansound = true
        
        botonaudio.src = "textures/soundon.png"
    }


}

botonaudio.onclick = () => { 
    if(primeravez){
        primeravez = false
        textoaudio.innerHTML = ""

    }
    if(cansound){
        cansound = false
        botonaudio.src = "textures/soundoff.png"
        sound.pause()
    }
    else{
        sound.play()
        cansound = true
        
        botonaudio.src = "textures/soundon.png"
    }

    
    

}

var hideme = document.getElementById("hideme")
hideme.onclick = () => {
    hideme.style.height = "0px"
    hideme.style.display = "none"
}



let pc = null
//phone or pc 
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
if (isMobile) {
  	console.log("You are using Mobile")
     pc = false

    centerimage.style.width = "85%";
    centerimage.style.maxWidth = "85%";
    centerimage.style.maxHeight = "70%";

    closeimage.style.maxWidth = "10%";
    
    
        
    
    let titulo = document.getElementById("p1")
    titulo.style.fontSize =   "8.2vw"

    centervideo.style.width = "85%";
    centervideo.style.maxWidth = "85%";
    centervideo.style.maxHeight = "70%";
    
    

} else {
    pc = true
	console.log("You are using Desktop")
    hideme.style.height = "0px"
    hideme.style.display = "none"
    
    closeimage.style.width = "2%";
    tarjeta.style.maxHeight = "90%"

}








/**
 * Base
 */
// Debug
/* const gui = new dat.GUI() */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


// Set background
const BACKGROUND_COLOR = 0x1C1C1C
/* 0xFAFBFD */
scene.background = new THREE.Color(BACKGROUND_COLOR)
/* scene.fog = new THREE.Fog(BACKGROUND_COLOR, 70, 400) */

const light = new THREE.AmbientLight( 0x404040 ); // soft white light
scene.add( light );

// spheres

const conjunto01 = [
    {imagen:"03/poemamadera.png", palabra : "principal" }, {imagen:"03/poemamaderacara.png", palabra : "caras" }, {imagen:"03/poemamaderasangran.png", palabra : "sangran" }, {imagen:"03/poemamaderasangran.png", palabra : "sangra" }, {imagen:"03/poemamaderasemana.png", palabra : "semanas" },  {imagen:"03/poemamaderaresbalan.png", palabra : "resbalan" }, {imagen:"03/poemamaderaresbalan.png", palabra : "resbala" }, {imagen:"03/poemamaderahartan.png", palabra : "hartan" }
]
conjunto01.lista = true

const conjunto02 = [
    {imagen:"03/poema2.png", palabra : "principal" }, {imagen:"03/poema2supo.png", palabra : "supo" }, {imagen:"03/poema2supo.png", palabra : "supo" }, {imagen:"03/poema2supo.png", palabra : "sumo" }, {imagen:"03/poema2cupo.png", palabra : "cupo" }, {imagen:"03/poema2cupo.png", palabra : "ocupo" }, {imagen:"03/poema2grillo.png", palabra : "grillo" }, {imagen:"03/poema2grillo.png", palabra : "brillo" }, {imagen:"03/poema2grillo.png", palabra : "pillo" }
]
conjunto02.lista = true

const closegaleria = [null, null]

closegaleria.cierre = true
closegaleria.lista = false






console.log(conjunto01)

const disponible = [
    {
        location: new THREE.Vector3(-90,-83,9),
        titulo: "Abismo del borde",
        color: "verde",
        mp3: "sounds/mar.mp3",
        images:  ["01/01.jpg", "01/03.JPG", "01/04.jpg", "01/05.jpg", "01/06.jpg", "01/07.gif", "01/video02.mp4", "01/video03.mp4", "01/video01.mp4","01/desal.mp4","01/marca.jpg","01/arbol.jpg","01/hojas.jpg"],
        siz: [[4600.0,3500.0],[4600.0,3500.0],[4600.0,3500.0],[9500.0, 3500.0],[6900.0,5000.0], [1200.0,700.0], [1200.0,600.0],[1900.0,1100.0],[1900.0,1100.0],[1900,1100],[4600,10070],[3600,4700],[4700,1700],[2300,900]],
        nameframe: ["A pleno tiempo", "Una copa se sumerge", "Porosa edad", "Danza al vacío", "Ala árbol", "Del mundo a la noche", "A la isla", "Vinimos, pasamos", "Y sin embargo se mueve", "Desal", "Mar", "arbol", "hojas", "17", "18", "19"]
    },{
        location: new THREE.Vector3(145,-40,-54), 
        titulo: "Los ojos del ojo",
        color: "rojo",
        mp3: "sounds/cambio.mp3",
        images: ["02/01.jpg", "02/02.jpg","02/03.jpg", "02/04.png", "02/05.gif", "02/video02.mp4", "02/video03.mp4", "02/video04.mp4", "02/video05.mp4","02/video06.mp4","02/06.jpg","02/07.jpg","02/ARAUCARIAS.jpg" ],
        siz: [[2600,2000],[2600, 2200],[5000,2700],[1500, 1000], [1200, 800], [1900,1100], [1900,1100], [1900,1100], [1900,1100], [940,1080], [5000,4300],[2300,1800],[1300,1300]],
        nameframe: ["Y nubes", "Mundo a la vista", "Mirar fijo", "Tres sangran", "Aleteos", "El aliento espeso","Pupila adentro", "Viento de raíces", "Rotaciones","A otra luz", "Mirar las raíces", "Arraigar la mirada","Eterno entorno"]
    },{
        location: new THREE.Vector3(0,38,94),
        titulo: "IN-EX HALAR",
        color: "azul",
        mp3: "sounds/espuma.mp3",
        images: ["03/01.gif", "03/espumasombrafinal.mp4", "03/gotera1final.mp4", "03/video04.mp4", "03/videorocafinal.mp4", "03/videogotera2final.mp4", conjunto01, conjunto02,"03/videonuevo.mp4", "03/grillo.mp3", "03/lluvia.mp3", "03/piedra.mp4","03/piedra2.mp4", "03/goterasola.mp4","03/last01.jpg"],
        siz: [[1106, 850], [1900,1100], [1900,1100], [1900,1100], [1900,1100], [1900,1100], [1023,700], [1023,700], [1920,1080], [200,200], [200,200], [1920,1080], [1920,1080], [1920,1080],[4600,3500]],
        nameframe: ["Parpadeamos", "En el agua, la sombra", "Caen, muerden", "Mundo abajo", "Se acumula y no sabe", "Muerden, resbalan", "Madera", "Piedra", "Que susurra o que se inquieta", "Sí", "Llovamos", "Se vuelve y no sabe", "Se rota y no sabe", "Y mueren, parcas", "Luz al mar", "16", "17", "18", "19"]
    },{
        location: new THREE.Vector3(70,-78,95),
        titulo: "La misma edad",
        color: "amarillo",
        mp3: "sounds/personas.mp3",
        images: ["04/01.jpg", "04/02.jpg", "04/03.jpg", "04/04.jpg", "04/05.JPG", "04/06.jpg", "04/08.gif", "04/09.png", "04/video01.mp4", "04/eje.jpg", "04/lines.jpg","04/last01.mp4","04/last02.mp4"],
        siz: [[5000,3300], [5000,3300], [5000,3300], [5000,3300], [4700,3500],[5000,3500],[1000,1350],[4000,3100], [20000,12000],[5000,3000],[1600,3500],[1920,1080],[1920,1080]],
        nameframe: ["Pulso, pulsa", "Años como astros", "Tiempo", "Masa", "La copa asciende", "Del sol naciente", "La piedra no sabe y parpadea", "Forma", "Que sigue naciendo", "Y pulsa", "Escribe el viento", "Crecer, morder", "La luz interrumpida", "15", "16", "17", "18", "19"]
    },{
        location: new THREE.Vector3(-149,35,-49),   
        titulo: "Yo",
        color: "azul",
        mp3: "sounds/yo.mp3",
        images: ["05/video02.mp4", "05/video04.mp4", "05/video05.mp3", "05/video06.mp3", "05/video07.mp3","05/sobre.mp3","05/personas.mp3", "05/video01.mp4", "05/video03.mp4"],
        siz: [[1900,1100],[1900,1100],[200,200],[200,200],[200,200],[200,200],[200,200],[1900,1100],[1900,1100]],
        nameframe: ["La conversación interminable", "Lámparas de sonido","Quiénes", "Nosotros", "En mí", "Sobre", "personas", "La trama encendida", "Una bandera", "12", "13", "14", "15", "16", "17", "18", "19"]
    },{
        location: new THREE.Vector3(45, 70, 30),
        titulo: "Reciclo",
        color: "amarillo",
        mp3: "sounds/sobre.mp3",
        images: ["06/01.png", "06/02.png", "06/04.jpg", "06/05.jpg", "06/06.png", "06/07.jpg","06/07.png", "06/08.png", "06/09.jpg","06/video01.mp4","06/video02.mp4","06/rocasombra.jpg","06/piedracorte.jpg","06/last02.jpg"],
        siz: [[3000, 2900], [4100, 3200], [2300, 1500], [2500, 3500], [2500, 3800],[5000,3200],[5000,4900],[4700,6100],[2600,1700],[1900,1100],[1900,1100],[4400,2200],[4700,3100],[5000,3300]],
        nameframe: ["Unos pocos segundos", "Edades acumuladas","El día que espera", "Hacer el poema", "El poema nos lee", "Dice el día", "Sus caras", "Luz de alas", "¡Tiempo, tiempo!", "Escribir el poema", "Dibujar aún, y escuchar", "Otro árbol viene", "Alguien va a hablar", "Reunir la luz", "Corte3", "15", "16", "17", "18", "19"]
    }       
]

console.log(disponible[0].images[1])



//sprite text 


/* function makeTextSprite( message, parameters )
    {
        if ( parameters === undefined ) parameters = {};
        var fontface = parameters.hasOwnProperty("fontface") ? parameters["fontface"] : "Courier New";
        var fontsize = parameters.hasOwnProperty("fontsize") ? parameters["fontsize"] : 18;
        var borderThickness = parameters.hasOwnProperty("borderThickness") ? parameters["borderThickness"] : 4;
        var borderColor = parameters.hasOwnProperty("borderColor") ?parameters["borderColor"] : { r:0, g:0, b:0, a:0 };
        var backgroundColor = parameters.hasOwnProperty("backgroundColor") ?parameters["backgroundColor"] : { r:0, g:0, b:255, a:1.0 };
        var textColor = parameters.hasOwnProperty("textColor") ?parameters["textColor"] : { r:0, g:0, b:0, a:1.0 };

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.font = "Bold " + fontsize + "px " + fontface;
        var metrics = context.measureText( message );
        var textWidth = metrics.width;

        context.fillStyle   = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," + backgroundColor.b + "," + backgroundColor.a + ")";
        context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + "," + borderColor.b + "," + borderColor.a + ")";
        context.fillStyle = "rgba("+textColor.r+", "+textColor.g+", "+textColor.b+", 1.0)";
        context.fillText( message, borderThickness, fontsize + borderThickness);

        var texture = new THREE.Texture(canvas) 
        texture.needsUpdate = true;
        var spriteMaterial = new THREE.SpriteMaterial( { map: texture, useScreenCoordinates: false, transparent: true } );
        var sprite = new THREE.Sprite( spriteMaterial );
        sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);
        return sprite;  
    }






var spritey = makeTextSprite( " Hello ", { fontsize: 44, textColor: {r:255, g:255, b:255, a:1.0}} )
spritey.position.set(-22,0,9);
scene.add( spritey );
 */




/**
 * Galaxy
 */
const parameters = {}

if(pc == true){
    parameters.count = 250000
    console.log(parameters.count)
    
    console.log(parameters.size)
}
else{
    parameters.count = 94000
    console.log(parameters.count)
    
    console.log(parameters.size)
}

parameters.radius = 5
parameters.branches = 3
parameters.spin = 1
parameters.randomness = 0.2
parameters.randomnessPower = 1
parameters.insideColor = '#ff6030'
parameters.outsideColor = '#1b3984'

let geometry = null
let materialp = null
let points = null




const positions = new Float32Array(parameters.count * 3)
const colors = new Float32Array(parameters.count * 3)
const scales = new Float32Array(parameters.count * 1)
const randomness = new Float32Array(parameters.count * 3)



const generateGalaxy = () =>
{
    // Destroy old galaxy
    if(points !== null)
    {
        geometry.dispose()
        materialp.dispose()
        scene.remove(points)
    }

    /**
     * Geometry
     */
    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)

    const colorInside = new THREE.Color(parameters.insideColor)
    const colorOutside = new THREE.Color(parameters.outsideColor)

    for(let i = 0; i < parameters.count; i++)
    {
        // Position
        const i3 = i * 3

        const radius = Math.random() * parameters.radius + 5

        const spinAngle = radius * parameters.spin
        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2
        
        const randomX =  Math.cos(Math.pow(Math.random(), parameters.randomnessPower)) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius 
        const randomY = Math.cos(Math.pow(Math.random(), parameters.randomnessPower)) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * (radius - 5) * 4 
        const randomZ =  Math.cos(Math.pow(Math.random(), parameters.randomnessPower) ) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius 

        positions[i3    ] = Math.sin(randomY) * (Math.cos(branchAngle + spinAngle) * radius + randomX)
        positions[i3 + 1] = randomY
        positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ

        // Color
        const mixedColor = new THREE.Color("rgb(0, 0, 0)")
        
        colors[i3    ] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b
        scales[i] = Math.random()
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
    geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
    
    geometry.scale(45,45,45)

    
    

    /**
     * Materialp
     */

     

    if(pc == true){
        materialp = new THREE.ShaderMaterial({
        
            depthWrite: false,
            //para el color negro, desactivar blending
            blending: THREE.AdditiveBlending,
            transparent: true,
            vertexColors: true,
            vertexShader: VertexShadergx,
            fragmentShader: FragmentShadergx,
            uniforms:{
                uTime: { value: 0 },
                uSize : { value: 1.2 }
            
            }
        })
    }
    else{

        materialp = new THREE.ShaderMaterial({
        
            depthWrite: false,
            /* blending: THREE.AdditiveBlending, */
            transparent: true,
            vertexColors: true,
            vertexColors: true,
            vertexShader: VertexShadergx,
            fragmentShader: FragmentShadergx,
            uniforms:{
                uTime: { value: 0 },
                uSize : { value: 1.5 }
            
            }
        })

    }
       
   
    

    /**
     * Points
     */
    points = new THREE.Points(geometry, materialp)
    scene.add(points)
}





const parameters2 = {}
parameters2.count = 5000
parameters2.size = 0.7




let materialp2 = new THREE.PointsMaterial({
    size: parameters2.size,
    sizeAttenuation: true,
    transparent : true,
    alphaMap : particleTexture,
    depthWrite: false,
    /* blending: THREE.AdditiveBlending, */
    vertexColors: true,
    color: new THREE.Color('#ff88cc')
})







const generateGalaxy2 = () =>
{
    /**
     * Geometry
     */
    const geometry2 = new THREE.BufferGeometry()

    const positions2 = new Float32Array(parameters2.count * 3)

    for(let i = 0; i < parameters2.count; i++)
    {
        const i3 = i * 3

        positions2[i3    ] = (Math.random() - 0.5) * 1000
        positions2[i3 + 1] = (Math.random() - 0.5) * 1000
        positions2[i3 + 2] = (Math.random() - 0.5) * 1000
    }

    geometry2.setAttribute('position', new THREE.BufferAttribute(positions2, 3))
 

    const material2 = new THREE.PointsMaterial({
        size: parameters2.size,
        sizeAttenuation: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending
    })

    /**
     * Points
     */
     const points = new THREE.Points(geometry2, materialp)
     scene.add(points)


}








/* gui.add(parameters, 'count').min(100).max(1000000).step(100).onFinishChange(generateGalaxy)
gui.add(parameters, 'size').min(0.001).max(0.1).step(0.001).onFinishChange(generateGalaxy)
gui.add(parameters, 'radius').min(0.01).max(50).step(0.01).onFinishChange(generateGalaxy)
gui.add(parameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy)
gui.add(parameters, 'spin').min(- 5).max(5).step(0.001).onFinishChange(generateGalaxy)
gui.add(parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy)
gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy) */
        



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 550)
/* camera.position.x = 0.5
camera.position.y = 0.5
camera.position.z = 0.5 */

camera.position.x = 150
camera.position.y = 150
camera.position.z = 150




scene.add(camera)


// AUDIO


// create an AudioListener and add it to the camera
const listener = new THREE.AudioListener();
camera.add( listener )
// create a global audio source
const sound = new THREE.Audio( listener )
// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader()


function getaudio(){
    console.log(currentaudio)
    
    sound.pause()
    audioLoader.load( currentaudio, function( buffer ) {
        sound.setBuffer( buffer )
        sound.hasPlaybackControl
        sound.setLoop( false )
        sound.setVolume( 1 )
        if(cansound){
            sound.stop()
            sound.play()
            botonaudio.src = "textures/soundon.png"
        }
        
    })

}




//raycaster

const raycaster = new THREE.Raycaster()


// Cursor
const cursor = {
    x: 0,
    y: 0
}

const mouse = new THREE.Vector2()

let rotatex = null
let rotatey = null
let rotatez = null

function map_range(value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}


let abierto = true

window.addEventListener('mousemove', (event) =>
{
    if(abierto){
        if(moveon == false){
        
            mouse.x = event.clientX / sizes.width * 2 - 1
            mouse.y = - (event.clientY / sizes.height) * 2 + 1
    
        //console.log(mouse)
    
        if(pc == true){
    
            if(cantmove == false){
                rotatex = locationgoto.x + Math.sin(mouse.x * Math.PI * 1.2)* 0.05
        
                rotatez = locationgoto.z + Math.cos(mouse.x * Math.PI * 1.2)* -0.05
        
                rotatey = locationgoto.y + mouse.y * Math.PI * 0.07* -0.2
                //console.log(camera.position.y)
    
                camera.position.y = rotatey
                camera.position.x = rotatex
                camera.position.z = rotatez
            }
            
    
    
        }
            
    
    
    
        }
    

    }
    
    
})


let radioesfera = 7

/// objetos
const objectsToTest = []
let cantidadoriginal = 0
const material = new THREE.MeshStandardMaterial( {color : new THREE.Color("rgb(0, 0, 0)")})
const geometria = new THREE.SphereGeometry(radioesfera, 9, 9)
let current = 0
let lista = []
let materiallist = []
let outline_mat = new THREE.MeshBasicMaterial({ color : new THREE.Color( 0xffffff ), side: THREE.BackSide });
let outline_geo = new THREE.SphereGeometry(radioesfera*1.1, 9, 9)




let listoutline = []

/* const disponible = [
    new THREE.Vector3(2,0,5),
    new THREE.Vector3(5,0,-4),
    new THREE.Vector3(0,8,4),
    new THREE.Vector3(9,-8,0)
] */


for(let i = 0; i < disponible.length; i++ ){
    current = i
    materiallist[i] = material.clone
    

    createsphere()
    
}

function createsphere(){
    //clone material
    let currentsp = new THREE.Mesh(geometria, material)
    currentsp.position.copy(disponible[current].location)
    lista.push(currentsp)
    lista[current].titulo = disponible[current].titulo
    lista[current].color = disponible[current].color
    lista[current].location = disponible[current].location  
    lista[current].mp3 = disponible[current].mp3
    lista[current].nameframe = disponible[current].nameframe
    lista[current].siz = disponible[current].siz
    lista[current].cierre = disponible[current].cierre
    lista[current].tipo = "punto"
    lista[current].numero = current

    let currentoutline = new THREE.Mesh(outline_geo, outline_mat)
    currentoutline.name = "outline"+current
    console.log(currentoutline.name + " este es el nombre del outline")
    currentoutline.position.copy(disponible[current].location)
    scene.add(currentoutline) 
    
    if( disponible[current].images == undefined){
        console.log("no imagenes en esfera")
    }
    else{
        for(let i = 0; i < disponible[current].images.length; i++){
        console.log(disponible[current].images[i])
        }
    }
    
    lista[current].images = disponible[current].images
    console.log(lista[current].images)
    scene.add(lista[current])
    



    console.log("se creo una sphere")
    objectsToTest.push(lista[current])

   
   
    

   


    objectsToTest.updateProjectionMatrix
    cantidadoriginal = objectsToTest.length
    console.log(cantidadoriginal + " cantidad original")
}    


// material shader



// Material geometry
const materialsh = new THREE.ShaderMaterial({
    vertexShader: testVertexShader,
     fragmentShader: testFragmentShader,
     transparent: true,
     depthTest:false,
     depthWrite:false,
     
     uniforms:
    {   
        
        uTexture: { value: null},
        uscale : { value: 1.0}
    }
});

let materialshaderlist = []

const materialsh2 = new THREE.ShaderMaterial({
    vertexShader: testVertexShader2,
     fragmentShader: testFragmentShader2,
     /* uniforms:
    {   
        uFrequency: { value: new THREE.Vector2(10, 5) },
        uTime: { value: 0 },
        uColor: { value: new THREE.Color('orange') },
        uTexture: { value: null},
        ucss : { value: null},
        uscale : { value: null}
    } */
})


for (var p = 0; p < 15; p++){

    materialshaderlist[p] = materialsh.clone()
}

console.log(materialshaderlist)




let planelist = null

let smallplane = new THREE.PlaneGeometry(2.5, 2.5, 20, 20)

for (var p = 0; p < 15; p++){

    planelist =smallplane.clone()
}

console.log(materialshaderlist)


const spheresimple = new THREE.SphereGeometry( 2, 2, 5, 5 )











let planes = new THREE.Mesh
let spheres = new THREE.Mesh

let valorgrados = 0

let grados = []
let radius = 15
const currentimages = []

const escalafix = []

function createimages(){


    if(cantidadoriginal < objectsToTest.length){

        objectsToTest.splice(cantidadoriginal , currentimages.length)

            console.log("cantidad eliminada es"+ currentimages.length + "cantidad de esfera originales es " + cantidadoriginal)

    }


    for(let i = 0; i < currentimages.length; i++){
        
        scene.remove(currentimages[i])
        
        console.log(currentimages)
    }
    
    
    /* materialshaderlist = null
    materialshaderlist = [] */
    
     
    grados = []

    valorgrados = (Math.PI * 2) / imageslist.length 

    for(let i = 0; i < imageslist.length; i++){
        
        let mingrade = valorgrados * (i + 1) 
        grados.push(mingrade)
    }

    

    console.log(grados)



   /*  materialshaderlist[p].uniforms.uTexture =  { value: imageslist[p] } */

    let nameframecarteles = []


    for (var p = 0; p < imageslist.length; p++){

        

        let random = Math.floor(Math.random() * grados.length)
        let randomgrado = grados[random]

        

        console.log(randomgrado)   




        /* materialshaderlist[p] = materialsh.clone() */

        /// check url 
        console.log(imageslist[p].textimagecss[0])

        let checkimage = null
        
        if (Array.isArray(imageslist[p].textimagecss)){
            checkimage = true
        }
        else{
            checkimage = checkURL(imageslist[p].textimagecss)
        }

        const escala = imageslist[p].siz[0] / ( imageslist[p].siz[1]*1)
        escalafix[p] = escala.toFixed(3)
        console.log(escalafix[p])

        console.log(checkimage + "la imagen fue check")
        if(checkimage){
            materialshaderlist[p].uniforms.uTexture =  { value: imageslist[p] }
            materialshaderlist[p].uniforms.uscale = { value: escalafix[p] }
            //escala    
        }

        else {
            // agregar cierre
            if(imageslist[p].cierre){
                materialshaderlist[p].uniforms.uTexture =  { value: CloseTexture }
                materialshaderlist[p].uniforms.uscale = { value: 1.0 }
                materialshaderlist[p].depthWrite = false
            }
            else{
                if(checkMP3(imageslist[p].textimagecss)){
                    materialshaderlist[p].uniforms.uTexture =  { value: PlatTexture }
                    materialshaderlist[p].uniforms.uscale = { value: 1.0 }
                }
                else{
                    materialshaderlist[p].uniforms.uTexture =  { value: imageslist[p] }
                    materialshaderlist[p].uniforms.uscale = { value: escalafix[p] }
                }
                
                
                /* materialshaderlist[p].depthWrite = false */
            }
            
        }

        /* if(checkimage == ) */


        
        /* materialshaderlist[p].update = true */

        
        planes[p]  = new THREE.Mesh(spheresimple, materialsh2)
        planes[p].depthWrite = false




       
        console.log("escala")
        console.log(imageslist[p].siz)
        console.log("escala")

        
         
        
        
        
        spheres[p] = new THREE.Mesh(smallplane, materialshaderlist[p])

        

        /* spheres[p].scale.set(3, 3, 1) */
        
       
        
        
        
/* 
        let currentporcentaje = imageslist[p].currentwidth / (1 * imageslist[p].currentheight)

            
        spheres[p].scale(1, currentporcentaje,) */

        


        //scale

        /* console.log( "width es" + imageslist[p].currentwidth) */

        
        ///texto



        
        







        var canvas2 = document.createElement('canvas');
        var size = 3400; // CHANGED
        canvas2.width = size;
        canvas2.height = size;
        var context = canvas2.getContext('2d');
        context.fillStyle = '#ffffff'; // CHANGED
        
        
        context.textAlign = 'center';
        context.font = '250px Arial';
        context.strokeStyle = "black"
        
        context.fillText(imageslist[p].nameframe[p], size / 2, size / 2);
        

        var amap = new THREE.Texture(canvas2);
        amap.needsUpdate = true;

        var mat = new THREE.SpriteMaterial({
            map: amap,
            transparent: false,
            alphaTest: 0.05,
            /* useScreenCoordinates: false, */
            color: new THREE.Color( 0xffffff ), // CHANGED,
            depthWrite : false
        })

        var sp = new THREE.Sprite(mat);

        sp.position.y += 1.5

        
        sp.scale.set( 4.8, 4.8, 0.1 ); // CHANGED

        planes[p].add(sp)



        


////////////////



        planes[p].add(spheres[p])
        



        planes[p].position.copy(locationgoto)


         
        let rotatex = Math.sin(randomgrado) * (radius - Math.floor(Math.random() * (radius/8)) * (Math.random() < 0.5 ? 1 : - 1) )
        let rotatez = Math.cos(randomgrado) * (radius - Math.floor(Math.random() * (radius/8)) * (Math.random() < 0.5 ? 1 : - 1) )
        let rotatey =  Math.floor(Math.random() * (radius / 3)) * (Math.random() < 0.5 ? 1 : - 1) 




        planes[p].position.y += rotatey
        planes[p].position.x += rotatex
        planes[p].position.z += rotatez





        if(checkimage){
            planes[p].tipo = "imagen"
        }

        else {
            if(imageslist[p].cierre){
                planes[p].tipo = "cierre"
            }
            else{
                planes[p].tipo = "video"
            }
            
        }

        
        planes[p].textimagecss = imageslist[p].textimagecss
        planes[p].textpalabra = imageslist[p].textpalabra
        currentimages[p] = planes[p]
        





        //// texto magico
        /* imageslist[p].nameframe

        planes[p].position.y += rotatey
        planes[p].position.x += rotatex
        planes[p].position.z += rotatez */






        scene.add(planes[p])

        objectsToTest.push(currentimages[p])
        objectsToTest.updateProjectionMatrix


        
        grados.splice(random, 1)

        
        
    }

    
    imageslist = []
    

    

    
    
}






/* locationgoto es la direccion del planeta  */

function createplanes(){
    for (var p = 0; p < imageslist.length; p++){
        console.log("nada")
        
    }
}







// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = false


controls.enableZoom = false
controls.minPolarAngle = 0.2*Math.PI


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    powerPreference: 'high-performance',
    antialias: true,
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1  ))

renderer.shadowMap.autoUpdate = false


generateGalaxy()
/* generateGalaxy2() */

/**
 * Post processing
 */
 let RenderTargetClass = null

 if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
 {
     RenderTargetClass = THREE.WebGLMultisampleRenderTarget
     console.log('Using WebGLMultisampleRenderTarget')
 }
 else
 {
     RenderTargetClass = THREE.WebGLRenderTarget
     console.log('Using WebGLRenderTarget')
 }
 
 const renderTarget = new RenderTargetClass(
     800,
     400,
     {
         minFilter: THREE.LinearFilter,
         magFilter: THREE.LinearFilter,
         format: THREE.RGBAFormat
     }
 )




     // Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
if(pc==true){
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}
else{
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 1))
}
effectComposer.setSize(sizes.width, sizes.height)

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)
const makerender = effectComposer.render()



// Antialias pass
if(pc == true){
    if(renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2)
{
    const smaaPass = new SMAAPass()
    effectComposer.addPass(smaaPass)

    console.log('Using SMAA')
}
}




// touch



let timetouchstar = 0
let timetouchend = 0
let timetouchdelta = 0
window.addEventListener('touchend', () =>
{
    timetouchend = clock.getElapsedTime()
    

    timetouchdelta = timetouchend - timetouchstar

    console.log('diferencia de tiempo')
    console.log(timetouchdelta)

    if(timetouchdelta < 0.2){
        goto()
        timetouchstar = 0
        timetouchend = 0
        timetouchdelta = 0
        
    }
    else
        timetouchstar = 0
        timetouchend = 0
        timetouchdelta = 0


})

window.addEventListener('touchstart', () =>
{
    timetouchstar = clock.getElapsedTime()
    console.log('touch star')
    console.log(timetouchstar)
})














// Animate
const clock = new THREE.Clock()

let rayOn = true



let currentIntersect = null
let locationgoto= new THREE.Vector3()
let currentaudio = "nada"
let currentworld = null
let currentp = null

// carga de imagenes 
let imageslist = []
function Loadimages(){
    
    for (var p = 0; p < currentworld.images.length; p++){
        console.log("current world es =" + currentworld.images[p])
        let textimage = "text"
        let textimagecss = "text"
        let textpalabra = []
        console.log(currentworld.images[p])
        currentp = p
        if( currentworld.images[p].lista){
            
            textimagecss = []
            textpalabra = []

            for (var i = 0; i < currentworld.images[currentp].length; i++){
                /* console.log("es arayyyyyyyyyyyyyyyyyyyyyyyyyyyyy") */
                
                if(i==0){
                    textimage = 'textures/'+currentworld.images[currentp][0].imagen
                }
                
                textimagecss[i] = 'textures/'+currentworld.images[currentp][i].imagen
                textpalabra[i] = currentworld.images[currentp][i].palabra
                console.log(textpalabra[i])
                console.log(textimagecss[i].imagen)

            }  
                      

        }
        else if( currentworld.images[p].cierre){
            console.log("preparando imagen cierre")
        }

        else if (currentworld.images[currentp].lista == undefined){
            console.log("NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO es aray")
            textimage = 'textures/'+currentworld.images[currentp]
            textimagecss = 'textures/'+currentworld.images[currentp] 
        }
        
        console.log(textimage)
        console.log(textimagecss)
        

        
        
        if(checkURL(textimage)!=true){
            textimage = textimage+".jpg"


        }

        imageslist[p] = textureLoader.load(
            // resource URL
            textimage,
        
            // onLoad callback
            function ( texture ) {
                
                console.log("termino cargar textura")
                let currentwidth = texture.image.width
                let currentheight = texture.image.height
                /* console.log(currentwidth, currentheight)
                console.log(texture.textimagecss) */
                /* let currentcss = texture.textimagecss */
        
            	},
        
            // onProgress callback currently not supported
            undefined,
        
            // onError callback
            function ( err ) {
                console.error( 'An error happened.' );
        
            }
        );


        if( currentworld.images[p].lista){

            imageslist[p].textimagecss = []
            imageslist[p].lista = true
        }
        else{
            imageslist[p].lista = false
        }

        if( currentworld.images[p].cierre){
            imageslist[p].cierre = currentworld.images[p].cierre
        }


        imageslist[p].textimagecss = textimagecss
        imageslist[p].textpalabra =  textpalabra
        imageslist[p].siz = currentworld.siz[currentp]
        imageslist[p].nameframe = currentworld.nameframe

       
        console.log(imageslist[p].textimagecss)
        
    
        
    }
}










/// goto

let clicktrue = true


window.addEventListener('click', goto, clicktrue)
let arrayinfo 

function goto(){
    
    for (var p = 0; p <= objectsToTest.length; p++){
    if(currentIntersect.object){
        switch(currentIntersect.object){

                       case objectsToTest[p]:



                if (objectsToTest[p].tipo == "punto") {


                    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
                    document.getElementById("centertext").style.color = "white"
                    document.getElementById("centertext").style.width = "100%"

                    scene.remove(scene.getObjectByName("outline"+p));

                    //modificar titulo

                    currenttitulo = objectsToTest[p].titulo
                    hasbeen = objectsToTest[p].numero
                    
                    
                    
                    



                    console.log('Punto numero '+ p)
                    console.log(objectsToTest[p].titulo)
                    console.log(objectsToTest[p].color)
                    locationgoto = objectsToTest[p].location
                    console.log(locationgoto)
                    moveon =     true
                    controls.target = locationgoto
                    currentaudio = objectsToTest[p].mp3
                    console.log(currentaudio)
                    getaudio()
                    currentworld = objectsToTest[p]
                    console.log("current world es =" + currentworld.images)
                    Loadimages()
                    createimages()
                    sound.pause()

                    
                
                     
                    break

                }
                 
                else if (objectsToTest[p].tipo == "imagen") {


                   
                    if(clicktrue){
                        centerimage.style.display = "block";
                        closeimage.style.display = "block"
                        backimage.style.display = "block"

                        if(Array.isArray(objectsToTest[p].textimagecss)){
                            arrayinfo = objectsToTest[p]
                            centerimage.style.content = "url('" + arrayinfo.textimagecss[0] + "')"
                            console.log(arrayinfo.textpalabra)
                            sound.pause()
                            
                            mic = true
                            recognition.start()
                            alert('Acepta el uso del micrófono. Lee fuerte y claro, para ver el cambio ');
                            
                            
                            
                        }

                        else{
                            centerimage.style.content = "url('" + objectsToTest[p].textimagecss + "')"
                        }
                        
                        cantmove = true 
                        controls.enabled = false

                        

                        

                        

                        console.log(centerimage.style.content)
                        

                        

                        objectsToTest[p].textimagecss
                        console.log("imagen aparece")
                        clicktrue = false
                        
                        break
                    }
            
                }

                else if (objectsToTest[p].tipo == "video") {

                    if(clicktrue){
                        centervideo.src = objectsToTest[p].textimagecss
                        
                        centervideo.style.display = "block";
                        closeimage.style.display = "block"
                        backimage.style.display = "block"


                        
                        cantmove = true 
                        controls.enabled = false
                        
                        

                        

                        console.log(centerimage.style.content)
                        

                        

                        objectsToTest[p].textimagecss
                        console.log("imagen aparece")
                        clicktrue = false
                        sound.pause()
                        break
                    }
            
                }

                else if (objectsToTest[p].tipo == "cierre") {


                    if(cantidadoriginal < objectsToTest.length){

                        objectsToTest.splice(cantidadoriginal , currentimages.length)
                    
                        console.log("cantidad eliminada es"+ currentimages.length + "cantidad de esfera originales es " + cantidadoriginal)
                    
                    }
                    
                    
                    for(let i = 0; i < currentimages.length; i++){
                            
                        scene.remove(currentimages[i])
                            
                        console.log(currentimages)
                    }

                   

                    break
                    
                }



            default:
                /* console.log('ah??') */

            }
        }
    }
    }




var travel1 = document.getElementById("01")
var travel2 = document.getElementById("02")
var travel3 = document.getElementById("03")
var travel4 = document.getElementById("04")
var travel5 = document.getElementById("05")
var travel6 = document.getElementById("06")



travel1.onclick = () => {
    document.getElementById("p1").innerHTML = "Abismo del borde"
                    notcloseimage()
                    let p = 0
                    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
                    document.getElementById("centertext").style.color = "white"
                    document.getElementById("centertext").style.width = "100%"
                    
                    console.log('Punto numero '+ p)
                    console.log(objectsToTest[p].titulo)
                    console.log(objectsToTest[p].color)
                    locationgoto = objectsToTest[p].location
                    console.log(locationgoto)
                    moveon =     true
                    controls.target = locationgoto
                    currentaudio = objectsToTest[p].mp3
                    console.log(currentaudio)
                    getaudio()
                    currentworld = objectsToTest[p]
                    console.log("current world es =" + currentworld.images)
                    Loadimages()
                    createimages()
                    currenttitulo = "Abismo del borde"
                    /* new THREE.MeshStandardMaterial( {color : new THREE.Color("rgb(0, 0, 0)")}) */
                    hasbeen = p
                    /* objectsToTest[0].material = new THREE.MeshStandardMaterial( {color : new THREE.Color("0xffffff")}) */
                    scene.remove(scene.getObjectByName("outline"+p))
}
travel2.onclick = () => {
    document.getElementById("p1").innerHTML = "Los ojos del ojo"
    notcloseimage()
    let p = 1
    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
    document.getElementById("centertext").style.color = "white"
    document.getElementById("centertext").style.width = "100%"
    console.log('Punto numero '+ p)
    console.log(objectsToTest[p].titulo)
    console.log(objectsToTest[p].color)
    locationgoto = objectsToTest[p].location
    console.log(locationgoto)
    moveon =     true
    controls.target = locationgoto
    currentaudio = objectsToTest[p].mp3
    console.log(currentaudio)
    getaudio()
    currentworld = objectsToTest[p]
    console.log("current world es =" + currentworld.images)
    Loadimages()
    createimages()
    currenttitulo = "Los ojos del ojo"
    hasbeen = p
    scene.remove(scene.getObjectByName("outline"+p))
}

travel3.onclick = () => {
    document.getElementById("p1").innerHTML = "IN-EX HALAR"
    notcloseimage()
    let p = 2
    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
    document.getElementById("centertext").style.color = "white"
    document.getElementById("centertext").style.width = "100%"
    console.log('Punto numero '+ p)
    console.log(objectsToTest[p].titulo)
    console.log(objectsToTest[p].color)
    locationgoto = objectsToTest[p].location
    console.log(locationgoto)
    moveon =     true
    controls.target = locationgoto
    currentaudio = objectsToTest[p].mp3
    console.log(currentaudio)
    getaudio()
    currentworld = objectsToTest[p]
    console.log("current world es =" + currentworld.images)
    Loadimages()
    createimages()
    currenttitulo = "IN-EX HALAR"
    hasbeen = p
    scene.remove(scene.getObjectByName("outline"+p))
}



travel4.onclick = () => {
    document.getElementById("p1").innerHTML = "La misma edad"
    notcloseimage()
    let p = 3
    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
    document.getElementById("centertext").style.color = "white"
    document.getElementById("centertext").style.width = "100%"
    console.log('Punto numero '+ p)
    console.log(objectsToTest[p].titulo)
    console.log(objectsToTest[p].color)
    locationgoto = objectsToTest[p].location
    console.log(locationgoto)
    moveon =     true
    controls.target = locationgoto
    currentaudio = objectsToTest[p].mp3
    console.log(currentaudio)
    getaudio()
    currentworld = objectsToTest[p]
    console.log("current world es =" + currentworld.images)
    Loadimages()
    createimages()
    currenttitulo = "La misma edad"
    hasbeen = p
    scene.remove(scene.getObjectByName("outline"+p))
}

travel5.onclick = () => {
    document.getElementById("p1").innerHTML = "Yo"
    notcloseimage()
    let p = 4
    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
    document.getElementById("centertext").style.color = "white"
    document.getElementById("centertext").style.width = "100%"
    console.log('Punto numero '+ p)
    console.log(objectsToTest[p].titulo)
    console.log(objectsToTest[p].color)
    locationgoto = objectsToTest[p].location
    console.log(locationgoto)
    moveon =     true
    controls.target = locationgoto
    currentaudio = objectsToTest[p].mp3
    console.log(currentaudio)
    getaudio()
    currentworld = objectsToTest[p]
    console.log("current world es =" + currentworld.images)
    Loadimages()
    createimages()
    currenttitulo = "Yo"
    hasbeen = p
    scene.remove(scene.getObjectByName("outline"+p))
}

travel6.onclick = () => {
    document.getElementById("p1").innerHTML = "Reciclo"
    notcloseimage()
    let p = 5
    document.getElementById("centertext").innerHTML = objectsToTest[p].titulo
    document.getElementById("centertext").style.color = "white"
    document.getElementById("centertext").style.width = "100%"
    console.log('Punto numero '+ p)
    console.log(objectsToTest[p].titulo)
    console.log(objectsToTest[p].color)
    locationgoto = objectsToTest[p].location
    console.log(locationgoto)
    moveon =     true
    controls.target = locationgoto
    currentaudio = objectsToTest[p].mp3
    console.log(currentaudio)
    getaudio()
    currentworld = objectsToTest[p]
    console.log("current world es =" + currentworld.images)
    Loadimages()
    createimages()
    currenttitulo = "Reciclo"
    hasbeen = p
    scene.remove(scene.getObjectByName("outline"+p))
}


////////////post



// oscuridad


const oscuridad = {
    precision: 'lowp',
    uniforms:
    {
        tDiffuse: { value: null },
        
        uTint: { value: new THREE.Vector3(1.0)}
    },
    vertexShader: `
        varying vec2 vUv;

        void main()
        {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

            vUv = uv;
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse; 
        uniform vec3 uTint;

        varying vec2 vUv;

        void main()
        {


            // Light point
        float strength = distance(vUv, vec2(0.5));
        /* strength = 1.0 - strength; */
        //se agrega el pow para tener la base elevada a la potencia. asi que logrando valores mas fuerte desde el centro y mas debiles de afuera
        strength = pow(strength, 3.0);

        // Final color
        vec4 newcolor = texture2D(tDiffuse, vUv);
        //originalmente donde está el 1.0,1.0,1.0. estaba el valor utint
    vec4 color = mix(newcolor, vec4(0.0,0.0,0.0, 0.4), strength);
    gl_FragColor = vec4(color);





            
            
        }
    `
}



function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  let iosdivice = iOS



if(pc == true && iosdivice == false){
    const oscuridadPass = new ShaderPass(oscuridad)
oscuridadPass.material.uniforms.uTint.value = new THREE.Vector3()
effectComposer.addPass(oscuridadPass)
gui.add(oscuridadPass.material.uniforms.uTint.value, 'x').min(- 1).max(1).step(0.001).name('red')
gui.add(oscuridadPass.material.uniforms.uTint.value, 'y').min(- 1).max(1).step(0.001).name('green')
gui.add(oscuridadPass.material.uniforms.uTint.value, 'z').min(- 1).max(1).step(0.001).name('blue')

}














let distance = null
let moveon = false


let oldElapsedTime = 0
 




let currenttitulo = ""

let hasbeen = null



/*  */

function entrada2(){

    gsap.to({},{
        duration: 3.8,
        onStart: () =>{
            document.getElementById("centertext").innerHTML = "Y Lo mismo es Fractal<br>Palabra-Medida-Imagen<br>aquí volvieron"
            const myTimeout = setTimeout(()=>{
                document.getElementById("centertext").style.opacity = "0"
            }, 1950);
            
        },
        onComplete: () =>{
            
            
            cantmove = false
            document.getElementById("centertext").style.color = "transparent"
            document.getElementById("centertext").innerHTML = ""
            document.getElementById("centertext").style.width = "0%"
            tarjeta.style.display = "block"
            if( pc == false){
                document.getElementById("textotarjeta").innerHTML = "Recuerde hacer doble click para interactuar con el entorno 3d.  Para acceder a los contenidos completos de LOMISMOESFRACTAL, debes autorizar la activación de sonido, micrófono y la visualización en loop de los videos haciendo clik en icono del parlante o clickear aquí abajo."
            
                hideme.style.display = "block"
                document.getElementById("centertext").style.opacity = "1"
                document.getElementById("centertext").classList.remove("fade")
                
            }
            
            document.getElementById("centertext").style.alignItems = "center"
            document.getElementById("centertext").style.top = "46%"
            document.getElementById("centertext").style.opacity = "1"
            document.getElementById("centertext").classList.remove("fade")
        }
    })
}

function entrada(){

    gsap.to({},{
        duration: 1.5,
        onStart: () =>{
            document.getElementById("centertext").innerHTML = "Y Lo mismo es Fractal<br>Palabra"
            
        },
        onComplete: entrada3
    }) 
}
function entrada3(){

    gsap.to({},{
        duration: 1.5,
        onStart: () =>{
            document.getElementById("centertext").innerHTML = "Y Lo mismo es Fractal<br>Palabra-Medida"
            
        },
        onComplete: entrada4
    }) 
}

function entrada4(){

    gsap.to({},{
        duration: 1.5,
        onStart: () =>{
            document.getElementById("centertext").innerHTML = "Y Lo mismo es Fractal<br>Palabra-Medida-Imagen"
            
        },
        onComplete: entrada2
    }) 
}


function inicio(){
    document.getElementById("centertext").style.opacity = "1"
    document.getElementById("centertext").innerHTML = "Y Lo mismo es Fractal"
    gsap.to(camera.position, {
        x:0.5,
        y:0.5,
        z:0.5,
        duration: 4.5,
        onComplete: entrada
    })

}


const tick = () =>
{
    
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Update material
    materialp.uniforms.uTime.value = elapsedTime


    ///// moivimiento

    if(moveon){
        camera.position.lerp(locationgoto, 0.043)
        distance = camera.position.distanceTo(locationgoto)

        if( distance < radioesfera ){
        
            document.getElementById("centertext").style.color = "transparent"
            document.getElementById("centertext").innerHTML = ""
            document.getElementById("centertext").style.width = "0%"
        }

        if( distance < 0.09 ){
            moveon = false
            // cambia de color aca
            document.getElementById("centertext").style.color = "transparent"
            objectsToTest[hasbeen].material = new THREE.MeshStandardMaterial( {color : new THREE.Color("0xffffff")})

            
            
            
        }
    }
    
    
    //cast a ray
    
     raycaster.setFromCamera(mouse, camera)

     
 
     
     
     
     const intersects = raycaster.intersectObjects(objectsToTest)
 
 
     for(const object of objectsToTest)
     {
         //aqui directamente toda la lista cambiar a
         
     }

     for(const intersect of intersects)
    {
        if(rayOn){
            //object es el atributo del ray intersectado de la lista. osea  alos objectos dentro de la lista. tambien se puede usar parasacar el punto de interseccion
            //console.log(intersect.point)
           
        }
        
    }

    if(intersects.length)
    {   
        if(currentIntersect == null)
        {
            console.log('mouse enter')
        }
        currentIntersect = intersects[0]




        for (var p = 0; p <= objectsToTest.length; p++){
            if(currentIntersect.object){
                switch(currentIntersect.object){
        
                               case objectsToTest[p]:
                                if(objectsToTest[p].tipo == "punto"){

                                    document.getElementById("p1").innerHTML = objectsToTest[p].titulo
                                    document.body.style.cursor = "pointer"
                                    

                                    
                             
                                    break
                                }
                        
                                else if (objectsToTest[p].tipo == "imagen" || objectsToTest[p].tipo == "video" || objectsToTest[p].tipo == "cierre" ) {

                                    document.body.style.cursor = "pointer"
                                    break
                            
                                }  
        
                    }
                }
            }

    }
    


    else{
        if(currentIntersect)
        {
            console.log('mouse leave')
            document.getElementById("p1").innerHTML = currenttitulo
            document.body.style.cursor = "url('./mcircle.png'), auto"
        }
        currentIntersect = null
    }







    // Update controls
    controls.update()




    setTimeout( function() {

        window.requestAnimationFrame(tick)
        //console.log(deltaTime)

    }, 1000 / 60 );



    // Render
    /* renderer.render(scene, camera) */
    
    if(pc == true){
        effectComposer.render()
    }
    else{
        renderer.render(scene, camera)
    }
    // Call tick again on the next frame
    
    raycaster.updateProjectionMatrix
}

tick()




/*                      WWMMMMGGGGGGGGGGGGGGGGGMMMMMMMMGGMMMMMMMMMMMMWWWWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMW
                       WWMMMMGGGGGGGGGGGGGGGGGGMMMMMMMMGGMMMMMMMMMMMMWWWWRWWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMW
                       WWMMMGGGGGGGGGGGGGGGGGGGMMMMMMMMMMMMMMMMMMMMGWWWWGMMRRMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMW
                      WWMMMGGGGGGGGGGGGGGGGGGGGGMMMMMGMMMMMMMMMMMGWWW.WWGMMMMMMWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMGGGGMMMMMMMMMMMMMMMMMMMMMMMW
                     WWMMMMGGGGGGGGGGGGGGGGGGGGGGGMMGGGMMGGMMMGGGWW....WGMMMMMMMWWWWWMMMMMMMMMMMGGGGGGGGGGGGGGGGGGGGGGGGGMGMMWWWWMMMMMMMMMMMMMMMW
                     WWMMMMGGGGGGGGGGGGGGGGGGGGGGGGMGGMMMMMMGGGWW......WWGMMMMMGGGGWMMRWWMMMMMMMMMMMGGGGGGGGMMMMGGGGGGGGGGMWWWWWWMMGMMMMMMMMMMMMW
                    WWMMMMGGGGGGGGGGGGGGGGGGGGGGGGMGGGGGGGGGGWWW........WWMMMMMMGGGGGGMWWMMMWMGGGGGGGGGGGGGGGGGGGGGGGGGGGGMWWMMGGGGGGGGGGMMMMMMMWW
                   WWMMMMMGGGWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGWW..WWWW....WWGMMMMMGGGGGGGGGGMMMMMMMMGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGMMMGMMMMMWWW
                  WWMMMMMGGWWWWWGGGGGGGGGGGGGGGGGGGGGGGGGWW.WWW...WW....WWGMMMMGGGGGGGGGGGGGGMMMWWWWGMGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGMGMMMWWWW
                  WWMMMMMMMW.WWWWGGGGGGGGGGGGGGGGGGGGGGGGWW.WW......WW....WWGMMMMGGGGGGGGGGGGGGGGMMWRMWWMWGGGGGGGGGGGGGGWGGGGGGGGGGGGGGGGMMMMWWWW
                 WMMMMMMMW...WWWGGGGGGGGGGGGGGGGGGGGGGWWWWWWWWW....WWW....WWMMMMGGGGGGGGGGGGGGGGGGGGGGWWMWWWMMMMGGGGGGGWWGGGGGGGGGGGGGMMMWWWWWMWW
                 WWMMMMMMMW..WWWWGGGGGGGGGGGGGGGGGGGGGWWWW.....WWWWWWWWW....WWMMMMGGGGWWGGGGGGGGGGGGGGGGGGGGGWMWMMMMMMMMWWGGMMMWWWWWWWWWWWWWWMMMW
                WWMMMMMMMMW.WW.WWGGGGGGGGGGGGGGGGGGGWWWW............WWWWW....WWMMMMGGGWWWWGGGGGGGMWWGGGGGGGGGGGGGGGGGGGGGWWGWWGGWWWWWWWWWMGMMMMGW
               WWMMMMMMMMMW.W..WWGGGGGGGGGGGGGGGGGGWWWW................WWWW...WWMMMMMMMWWWWGGGGGGWWWGGGGGGGGGGGGGGGGGGGGGGWGGGGGGGGGGGGGGGGGGMMGW
               WWMGGGGGGMMW.WW..WGGGGGGGGGGGGGGGGGWWW.............WWWW...WWWWW.WWMMMMMMMW.WWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWWGGGGGGGGGGGGGGGGGGGGW
              WWMGGGGGGGGGW.WW..WGGGGGGGGGGGGGGGGWWWWWWWWWWW........WWWW..WW.WWWWWMMMMMMMW..WWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGWGGGGGGGGGGGGGGGGGGGGMW
             WWMGGGGGGGGGGGWW...WWGGGGGGGGGGGGGGGGWWWWWWWWWWWWWWW.....WWWW.WWW.W.WWWMMMMMMW...WWWWGGGGGGGGGGGGGGGGGGGGGGGGGWWGGGGGGGGGGGGGGGGGGGGW
            WWMGGGGGGGGGGGGWWW..WWGGGGGGGGGGGGGGGGWWWGGGMGGMMMMMWWWWWW.WWW...WWW...WWMMMMMMW....WWWWGGGGGGGGGGGGGGGGGGGGGGGWWGGGGGGGGGGGGGGGGGGGMW
           WWMGGGGGGGGGGGGGW.WW.WWGGGGGGGGGGGGGGGWWWWGGGWGGWWWGWWWWWWWWWWW.....WW...WWWMMMMMW.....WWWWWGGGGGGGGGGGGGGGGGGGGWGGGGGGGGGGGGGGGGGGGGGW
          WWGGGGGGGGGGGGGGGW.WWWWGGGGGGGGGGGGGGGWWWWWWWGGWWW.WWWW...WWWW.......WWWWW.WWWMMMMW...WW..WWWWGGGGGGGGGGGGGGGGGWWWWWWWWWWGGGGGGGGGGGGGW
          WWMGGGGGGGGGGGGGGGWW...WWGGGGGGGGGGGGGMWWWWWWWWWWWWWWWW.................WWW...WWMMMMWW........WWWWGGGGGGGGGGGGGGWWWWWWWWWGGGGGGGGGGGGGGWW
         WWGGGGGGGGGGGGGGGGGGGWW.WWGGGGGGGGGGGGGMW.WWWWWWWWWWWWW...................WWWW..WWWMMMWW....WWWWW.WWWWGMGGGGGGGGGWGGGGGGGGGGGGGGGGGGGGGGWW
        WWGGGGGGGGGGGGGGGGGGGGGWWWWWGGGGGGGGGGGMMW...WWWWWWWWW....................WWWWWWW.WWWWWMWW..WWWWWWWWWWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWW
       WWGGGGGGGGGGGGGGGGGGGGGGGGWWWGGGGGGWWWGGMMW.......WWWWWWW..................WWWW..WWWWWWWWWMWWWWWWWWWWWWWWWWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWW
      WWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWW.WWGMMW...............................WW.....WWWWWWW.WWGWGGGGGWWWWWWWWWWWWWWWWGGWGGGGGGGGGGGGGGGGGGGGGGWW
     WWWWWGGGGGGGGGGGGGGGGGGGGGGGGGWGGGGWW..WWGMW.............................WWW.......WW..WWWWWGWWWWWGWWW....WWWWW..WWGGGGGGGGGGGGGGGGGGGGGGGGGWW
    WWGWGGGGGGGGGGGGGGGGGGGGGGGGGGGWWGGW......WMWWW.......................WWWWW........WW.....WWWWWWWWWWWW.....WWWWWWWWWGGGGGGGGGGGGGGGGGGGGGGGGGWW
    WWGGGGGGGWGGGGGGGGGGGGGGGGGGGGGGWWWWW..........WWWWW................WWWWWW.........WW.....WWWWWWWWWW......WWW...WWWGGWGGGGGGGGGGGGGGGGGGGGGGW
   WWGGGGGGGGGKIZUMONOGATARIGGGGGGGGWWWW..............WWWWWWW...WWWWWWWWW..............WW......WWWWWWW.......WWWWW.WWWGGGGGGGGGGGGGGGGGGGGGGGGGGW
   WWGGWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWWWW...................WWWWWW......................WW........WWWWWW......WW.WWWWWWGGGGWGGGGGGGGGGGGGGGGGGGGWW
   WWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWWWWW.........................................W....WWW......................WWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGW
    WWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWTW.WW.......................................WW.....WWW....................WWWWWGGGGGGGGGGGGGGWGGGGGGGGGGGGW
    WWGWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWTWW.WW......................................WW.......WWW..................WWWWWGGGGGGGGGGGGGWGGGGGGGGGGGGGW
     WWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGWO.WW.WW...................................WWW..........WWW...............WWWWWGWGGWGGGGGGGGGWGGGGGGGGGGGGGGW
      WWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGWO..WW.WW................................................WWWWW..........WWWWWWGGGGWGGGGGGGWWGGGGGGGGGGGGGGW
       WWGGGGGGGGGGGGGGGGGGGGGGGGWWWWWW...WW.WW...............WWW................................WWWWWWWWWWWWWWWWWGGGGWGGGGGGGWWGGGGGGGGGGGGGGGW
       WWWGGGGGGGGGGGGGGGGGGGGWWWWRRRRW....WW.WW...............WWW...................................WWWWWWWWGGGGGGGGGGGGGWGGGWGGGGWGGGGGGGGGGGW
        WWWGGGGGGGGGGGGGGGGGWWMRRRRRRRW.....WWWWW................WWWW...................................WWWGGGGGGGGGGGGGGGWGWGGGGGGGGGGGGGGGGGWW
         WWWGGGGGGGGGGGGGGGWWMRRRRRRRRW.......WW.WW.................WWWWWW......WW....................WWWGGGGGGGGGGWGGGGGGWWGGGGGGGGWWGGGGGGGGW
          WWWGGGGGGGGGGWWWWWRRRRRRRRRRW........WW.WW.....................WWWWWWWW...................WWWGGGGGGGGGGGGGGGGGGGGGGGGGGGWGGWGGGGGGGWW
          WWWWGGGGGWWWW..WWRRRRRRRRRRRWT.........WWWWW...........................................WWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWGGGGGGGW
            WWWGWWWW....WWRRRRRRRRRRRRWW..........WWWWW.......................................WWWWRRRWWWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWGGGGGW
              WWW......WWRRRRRRRRRRRRWWW............WWWWW.................................WWWWWRRRRRRRRWWGGGGGGGGGGGGGGGGGGGGGGGGGGGGGWGGGGWW
         WWWWW.WW.....WWRRRRRRRRRRRRRWTW..............WWWWW.........................WWWWWWWRRRRRRRRRRWOWWWWWWGGGGGGGGGGGGGGGGGGGGGGGGGWGGGW
WWWWWWWWWW.W...W....WWWRRRRRRRRRRRRRRW.W................WWWWW.................WWWWWWWWWRRRRRRRRRRRRRAWWW...WWWWWGGGGGGGGGGGGGGGGGGGGGGWGGW
WW.............W...WWRRRRRRRRRRRRRRRWW.W...................WWWW.........WWWWWWW..WWWWRRRRRRRRRRRRRRATWW........WWWWWGGGGGGGGGGGGGGGGWGGWW
...............WW.WWRRRRRRRRRRRRRRRRWT.W.....................WWWWWWWWWWWW......WWWWWRRRRRRRRRRRRRROTTW............WWWWWGGGGGGGGGGGGWGGWW
................WWWRRRRRMMMRMMMRRRRWW..W....................................WWWWWWRRRRRRRRRRRRRWATTTW................WWWWWWGGGGGGGGGWW
................WWWRRRMMMMMMMMMMMRMWT..W.................................WWWWWWWRRRRRRMRMMMRRRAOTTTWW....................WWWWWWWWWWWWWWW
..............WWWRRRRMMMMMMMMMMMMMMWT.TW..............................WWWWTTWWRRRRRWWAARMMMWAOTTTTWW..................................WWWWWWWWWWWW
..............WWWWWWWMMMMMMMMMMMMMMWT.WT............................WWW...TWWRRRRRAOOOAMMMRAOTTTTTW............................................WWW
 */